<template>
<div>
  <title-header :title="title" :buttons="[]"></title-header>

  <v-form v-model="validData">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
          outlined
            label="*Nombre"
            v-model="group.name"
            :rules="rules.name">
          </v-text-field>

           <v-text-field
            outlined
            label="Descripción"
            v-model="group.description">
          </v-text-field>

          <p class="ml-8"><small>*Campos obligatarios</small></p>
          <v-btn class="ml-6 mr-6"  outlined rounded color="primary"  dark @click="cancel()">Cancelar</v-btn>
          <v-btn rounded color="primary" @click="createClicked()" :disabled="!validData">Crear grupo</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
  <v-dialog v-model="dialog" persistent max-width="480">
    <v-card>
      <v-card-title class="headline">Felicidades</v-card-title>
      <v-card-text>El grupo se ha guardado satisfactoriamente.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="cancel()">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar color="primary" v-model="showSnackbar">
    {{snackBarText}}
    <v-btn text @click="showSnackbar = false">Cerrar</v-btn>
  </v-snackbar>
</div>
</template>

<script>
import { mapActions } from 'vuex'
import TitleHeader from '@/components/app/title-header/title-header'
import { GROUPS_CREATE_GROUP } from '@/store/actions.type'
import rules from '@/constants/input-validation'

export default {
  name: 'group-create',
  props: {},
  data() {
    return {
      title: 'Nuevo grupo',
      group: {
        name: '',
        description: ''
      },
      snackbarTimeout: 1000,
      snackBarText: '',
      showSnackbar: false,
      dialog: false,
      validData: false,
      rules
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      createGroup: GROUPS_CREATE_GROUP
    }),
    cancel() {
      this.$router.push('/settings/groups')
    },
    createClicked() {
      this.createGroup(this.group)
        .then(response => {
          this.dialog = true
        })
        .catch(e => {
          this.snackBarText = e.message
          this.showSnackbar = true
        })
    }
  },
  components: {
    TitleHeader
  }
}
</script>

<style>
</style>
