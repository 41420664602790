<template>
  <v-toolbar flat>
    <v-toolbar-title>{{title}}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      class="ma-2"
      rounded
      color="primary"
      v-for="button in buttons"
      v-bind:key="button.text"
      @click="routeTo(button.urlSlug)"
    >{{button.text}}</v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 'TitleHeader',
  props: ['title', 'buttons', 'search'],
  data() {
    return {}
  },
  computed: {},
  methods: {
    routeTo(urlSlug) {
      this.$router.push(urlSlug)
    }
  },
  components: {}
}
</script>

<style>
#title-header {
  width: 100%;
  height: 5rem;
  background-color: #818181;
  color: #fff;
  padding: 1rem 0 0 24px;
}
</style>
